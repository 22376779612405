.emoji{
  position: absolute;
  z-index: 5;
  width: 120px;
  height: 120px;
  background: center center #fff no-repeat;
  background-size:80px 80px;
  border-radius: 10%;
  box-shadow: 0 60px 60px 0 rgba(0, 0, 0, 0.13);

  $emojis: 'earth','rock','handshake','speaker','rocket','script','newspaper','hello', 'gear','computer',
  'shopping','art','computer3', 'nerd','invader','mobile', 'coffee','first','robot',
  'computer2','book','hashtag','print','picture','video','film', 'bar-chart', 'writing','loudspeaker',
  'chart', 'idea', 'glasses','strong','coffee','first','robot','mobile','education','food','health','you','animals';

  @each $emoji in $emojis{
    &.-em-#{$emoji}, &.emoji-#{$emoji}{
      background-image: url('../images/home/emoji/#{$emoji}.png');
    }
  }
}
