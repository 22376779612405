
/* main titles */
.meta-title{
  position: relative;
  z-index: 2;
  overflow: hidden;


}

.meta-title-container {
  border:0px solid transparent;
  padding: 0;
  width: 100%;
  transform: matrix(1, 0, 0, 1, 0, 0);


  .meta-title-header{
    border:0;
    position: relative;
    contain: layout style;

    h1{
      @extend .font-36;
      @extend .font-md-65;
    }

    h2{
      @extend .font-30;
      @extend .font-md-60;
    }
    h3{
      @extend .font-26;
      @extend .font-md-50;
    }

    h4{
      @extend .font-20;
      @extend .font-md-40;
    }
    h5{
      @extend .font-16;
      @extend .font-md-30;
    }
    h6{
      @extend .font-14;
      @extend .font-md-20;
    }

    .meta-title-shapes {

      position: absolute;
      top: -10px;
      left: -5px;
      right: -5px;
      bottom: -50px;
      overflow: hidden;
      /*background: $primary-120;*/
      display: block;
      contain: strict;


      .meta-title-shape {

        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        will-change: transform;

        border:1px solid #333;
        -webkit-border-radius: 20%;
        -moz-border-radius: 20%;
        border-radius: 20%;


        &.-v1 {
          width: 650px;
          height: 650px;
          margin: -325px 0 0 -325px;

          background: $primary-dark;
        }
        &.-v2 {
          width: 440px;
          height: 440px;
          margin: -220px 0 0 -220px;

          background: $secondary-light;
        }
        &.-v3 {
          width: 270px;
          height: 270px;
          margin: -135px 0 0 -135px;
          background: $primary;
        }
      }

      .square-shape{
        position: absolute;
        top: 0;

        will-change: transform;
        transform:rotate(75deg);

        border:1px solid #333;
        -webkit-border-radius: 20%;
        -moz-border-radius: 20%;
        border-radius: 20%;

        &.-sh1{
          width: 40%;
          height: 400px;
          left:5%;
          background: $primary;
        }
        &.-sh2{
          width: 50%;
          height: 200px;
          top:-5%;
          right:10%;
          background: $primary-lighter;
        }
      }
    }


  }

  .meta-title-header-blend {

    color: $primary-120;
    background: #fff;

    mix-blend-mode: screen;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    position: relative;
    z-index: 1;

    &::before {

      content: "";
      position: absolute;
      top: -12px;
      left: -6px;
      right: -6px;
      bottom: -52px;
      z-index: -1;
    }

    h1, h2, h3, h4, h5, h6 {
      line-height: 1.2;
      font-weight: bold;
      display: inline-block;
      padding: 0;
      margin: 0;
      letter-spacing: normal;
      text-rendering: optimizeSpeed;
    }


  }
}
