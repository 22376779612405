@use "sass:math";
/*
grey light : #5D839D
green-blue : #ababab
blue : #0028F5 / #67D6FB
*/

// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=0028F5&secondary.color=67D6FB
// https://in-your-saas.github.io/material-ui-theme-editor/
/*
{"palette":{"common":{"black":"rgba(51, 51, 51, 1)","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"rgba(51, 83, 247, 1)","main":"rgba(0, 40, 245, 1)","dark":"rgba(0, 28, 171, 1)","contrastText":"#fff"},"secondary":{"light":"rgba(72, 149, 175, 1)","main":"rgba(103, 214, 251, 1)","dark":"rgba(133, 222, 251, 1)","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}}
*/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap');

@font-face {
font-family: 'CircularStd';
src: local('CircularStd-Medium'),
url('../fonts/Circular/CircularStd-Medium.svg#CircularStd') format('svg'),
url('../fonts/Circular/CircularStd-Medium.woff') format('woff'),
url('../fonts/Circular/CircularStd-Medium.ttf') format('ttf'),
url('../fonts/Circular/CircularStd-Medium.eot') format('embedded-opentype')
;
font-style: normal;
}
@font-face {
font-family: 'CircularStd';
src: local('CircularStd-MediumItalic'),
url('../fonts/Circular/CircularStd-MediumItalic.svg#CircularStd') format('svg'),
url('../fonts/Circular/CircularStd-MediumItalic.woff') format('woff'),
url('../fonts/Circular/CircularStd-MediumItalic.ttf') format('ttf'),
url('../fonts/Circular/CircularStd-MediumItalic.eot') format('embedded-opentype')
;
font-style: italic;
}
@font-face {
font-family: 'CircularStd';
src: local('CircularStd-Bold'),
url('../fonts/Circular/CircularStd-Bold.svg#CircularStd') format('svg'),
url('../fonts/Circular/CircularStd-Bold.woff') format('woff'),
url('../fonts/Circular/CircularStd-Bold.ttf') format('ttf'),
url('../fonts/Circular/CircularStd-Bold.eot') format('embedded-opentype')
;
font-weight: bold;
}
@font-face {
font-family: 'CircularStd';
src: local('CircularStd-Bold'),
url('../fonts/Circular/CircularStd-Bold.svg#CircularStd') format('svg'),
url('../fonts/Circular/CircularStd-Bold.woff') format('woff'),
url('../fonts/Circular/CircularStd-Bold.ttf') format('ttf'),
url('../fonts/Circular/CircularStd-Bold.eot') format('embedded-opentype')
;
font-weight: 500;
}
@font-face {
font-family: 'CircularStd';
src: local('CircularStd-Bold'),
url('../fonts/Circular/CircularStd-Bold.svg#CircularStd') format('svg'),
url('../fonts/Circular/CircularStd-Bold.woff') format('woff'),
url('../fonts/Circular/CircularStd-Bold.ttf') format('ttf'),
url('../fonts/Circular/CircularStd-Bold.eot') format('embedded-opentype')
;
font-weight: 700;
}

@font-face {
font-family: 'CircularStd';
src: local('CircularStd-BoldItalic'),
url('../fonts/Circular/CircularStd-BoldItalic.svg#CircularStd') format('svg'),
url('../fonts/Circular/CircularStd-BoldItalic.woff') format('woff'),
url('../fonts/Circular/CircularStd-BoldItalic.ttf') format('ttf'),
url('../fonts/Circular/CircularStd-BoldItalic.eot') format('embedded-opentype')
;
font-weight:500;
font-style: italic;
}
$primary: #0028F5;
$primary-dark: #0000c1;
$primary-120: #000646;
$primary-light: #6d56ff;
$primary-lighter: #000eff;

$secondary: #67D6FB;
$secondary-dark: #22a4c8;//#4895af;
$secondary-lighter:#d7dcff;// #85defb;
$secondary-light:#9fffff;// #85defb;
$third: #707070;

$red: #FB4E4E;
$red-darker: #E93C3C;
$red-lighter: #FF6262;

$orange: #ff7140;
$orange-light: #FBE3D4; //rgba(255, 113, 64, 0.2);
$green: #39b54a;
$yellow: #fff125;
$dark: #3E3E3E;


$primary: #333EFF; // 80%
$primary-dark: #1A26FF; // 90%
//$primary-darker #000EFF; // 100% // override bescause not enought dark
$primary-darker: #000EFF; // 100%
$primary-darkest: #000646;
$primary-light: #4D56FF; // 70%
$primary-lighter: #666EFF; // 60%

$primary-80: $primary;
$primary-90: $primary-dark;
$primary-100: $primary-darker;
$primary-120: $primary-darkest;
$primary-70: $primary-light;
$primary-60: $primary-lighter;


$secondary: #B3B7FF; //30%
$secondary-dark: #999FFF;// 40%;
$secondary-darker: #8086FF;//50%;
$secondary-light:#CCCFFF;// 20%;
$secondary-lighter:#E6E7FF;// 10%;
$secondary-lightest:#9fffff; // not enought light;


$grey: #999BB5;
$grey-dark: #666A90;
$grey-darker: #33386B;
$grey-darkest: #000646;
$grey-light: #CCCDDA;
$grey-lighter: #E6E6ED;
$grey-lightest: #F2F3F6;


$primary-hover: $primary-dark;

$gradien-bg: linear-gradient(45deg, $primary, $secondary);
$gradien-H-bg: linear-gradient(90deg, $primary-dark, $secondary-dark);

$gradien-reverse-bg: linear-gradient(45deg, $secondary, $primary);
$gradien-reverse-h-bg: linear-gradient(90deg, $secondary, $primary);




$gray-light: #EFE6EF;
$teal: #00a099;
$custom-file-text: (
  en: "Browse",
  es: "Elegir",
  fr: "Parcourir"
);


$hover-opacity: 0.8;

.aviva-bg{ background-color: #ffd900; }
.aviva-color{ color:#004fb6; }
.btn-aviva{
  @extend .primary-bg;
  background-color: #004fb6;;
  color:#ffd900;
}

#mask{
  position: fixed;
  top:0;
  left:0;

  z-index:1;
  &.open{
    width: 100%;
    height:100%;
  }
  &.visible{
    background: rgba(128,128,128,0.25);
  }

}

#popup{
  position: fixed;
  background: #FFF;
  z-index:99;
  display: none;
  max-height: 90vh;
  overflow-y: auto;
  &.open{
    @extend .box;
    display: block;
    padding:25px;
    border-radius: 20px;
    top:5vh;
    left:15%;
    width: 70%;
  }


}

.custom-file-label::after{content:"Parcourir"!important;}


.primary-bg{ background-color:$primary; }
.primary-color{color:$primary;}

.primary-H-bg{ background-color:$primary-dark; }
.primary-H-color{ color:$primary-dark;}
.primary-HH-bg{ background-color:$primary-120;}
.primary-HH-color{ color:$primary-120;}

.primary-L-bg{ background-color:$primary-light; }
.primary-L-color{color:$primary-light;}
.primary-LL-bg{background-color:$primary-lighter;}
.primary-LL-color{color:$primary-lighter;}

.secondary-bg{ background-color:$secondary; }
.secondary-color{color:$secondary;}
.secondary-H-bg{ background-color:$secondary-dark; }
.secondary-H-color{color:$secondary-dark;}
.secondary-HH-bg{ background-color:$secondary-darker; }
.secondary-HH-color{color:$secondary-darker;}
.secondary-L-bg{ background-color:$secondary-light; }
.secondary-L-color{color:$secondary-light;}
.secondary-LL-bg{ background-color:$secondary-lighter; }
.secondary-LL-color{color:$secondary-lighter;}
.secondary-LLL-bg{ background-color:$secondary-lightest; }
.secondary-LLL-color{color:$secondary-lightest;}

.third-bg{ background-color:$third; }
.third-color{color:$third;}
.teal-bg{ background-color:$teal; }
.teal-color{color:$teal;}

.gradien-bg{background: $gradien-bg;}
.gradien-H-bg{background: $gradien-H-bg;}

.gray-light-bg{ background-color:$gray-light; }
.gray-light-color{color:$gray-light;}


.white-bg{ background-color:#ffffff; }
.white-color{color:#ffffff!important;}


.red-bg{ background-color:$red; }
.red-color{color:$red!important;}

.green-bg{ background-color:$green; }
.green-color{color:$green!important;}

.box{ box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
background: #fff; }


.btn-primary{
  @extend .primary-bg;

  &:hover{
    color:#fff;
    opacity: 0.8;
  }
}
.btn-outline-primary{
  border:1px solid $primary;
  @extend .primary-color;
}

.btn-primary-H{
  @extend .primary-H-bg;
  color:#fff;
  &:hover{
    opacity: 0.8;
    color:#fff;
    text-decoration: none;
    font-style:italic;
  }
}
.btn-outline-primary-H{
  border:1px solid $primary-dark;
  @extend .primary-H-color;
}

.btn-primary-L{
  @extend .primary-L-bg;
  color:#fff;
  &:hover{
    opacity: 0.9;
    color:#fff;
    text-decoration: none;
    font-style:italic;
  }


}

.btn-outline-primary-L{
  border:1px solid $primary-light;
  @extend .primary-L-color;
}

.btn-secondary{
  @extend .secondary-bg;
  border-color:$secondary;
}
.btn-outline-secondary{
  border:1px solid $secondary;
  @extend .secondary-color;
}




.btn-secondary-H{
  @extend .secondary-H-bg;
}
.btn-outline-secondary-H{

  border:1px solid $secondary-dark;
  @extend .secondary-H-color;
}
.btn-link{
  background-color: $secondary;
  color:$dark;

  &:hover{
    background-color:$primary-lighter;
    color:white;
    text-decoration: none;
  }
}
.btn-outline-white{
  background-color:white;
  border:1px solid $dark;
  color:$dark;
  text-decoration: none;
  &:hover{
    background-color:$primary-lighter;
    border:1px solid $primary-lighter;
    color:white;

  }
}


.border-primary{ border:2px solid $primary; }
.border-primary-H{ border:2px solid $primary-dark; }
.border-primary-L{ border:2px solid $primary-light; }
.border-secondary{ border:2px solid $secondary; }
.border-secondary-H{ border:2px solid $secondary-dark; }
.border-secondary-L{ border:2px solid $secondary-light; }

.border-top-primary{ border-top:2px solid $primary; }
.border-top-primary-H{ border-top:2px solid $primary-dark; }
.border-top-primary-L{ border-top:2px solid $primary-light; }
.border-top-secondary{ border-top:2px solid $secondary; }
.border-top-secondary-H{ border-top:2px solid $secondary-dark; }
.border-top-secondary-L{ border-top:2px solid $secondary-light; }

.border-20{ border-radius: 20px; }
.border-50{ border-radius: 50px; }

.emoji{
   font-family:Apple Color Emoji;
}
@for $i from 1 through 10 {
  .z-#{$i}{
    z-index:#{$i};
  }
}
@for $i from 7 through 70 {
  .font-#{$i}{
    font-size:#{$i}px;
  }
}
@media (min-width: 768px) {
  @for $i from 7 through 70 {
    .font-md-#{$i}{
      font-size:#{$i}px;
    }
  }
}
.opacity-0{ opacity: 0; }
.opacity-10{ opacity: 0.1; }
.opacity-20{ opacity: 0.2; }
.opacity-25{ opacity: 0.25; }
.opacity-50{ opacity: 0.5; }
.opacity-75{ opacity: 0.75; }
.opacity-80{ opacity: 0.8; }
.opacity-90{ opacity: 0.9; }
.opacity-100{ opacity: 1; }
