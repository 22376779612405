@use "sass:math";
@import 'public';
@import '_achievements';



::selection {
  color: #fff;
  background: $primary;
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body{
  /*cursor: none!important;*/
  color: $secondary;
}




section#screen-intro{
  height: 100vh;
  min-height: 800px;
  overflow: hidden;
  position: relative;
  .-em-earth{
    top:200px;
    left:0;
  }
  .-em-rocket{
    top:330px;
    right:50px;
  }
}
/** STRATS */
#cards{
  border-radius: 100px 100px 0 0;
  background: $primary;
  margin-top:auto;
  z-index: 6;
  text-align: center;
  height: 450px;
  max-height: 450px;
  overflow: hidden;
  position: relative;

  $card-height: 346px;
  $card-width: 286px;
  $bottomCard: -20px;
  #cards-content{

    position: relative;
    width: 100%;
    height: 450px;
    max-height: 450px;

    .card{
      position:absolute;
      width: $card-width;
      height: $card-height;
      border-radius: 30px 30px 0 0;
      box-shadow: 0 60px 60px 0 rgba(0, 0, 0, 0.13);
      border: solid 1px #e4ebef;
      background-color: #ffffff;
      top:450-$card-height;
      left:50%;
      margin:$card-height*2 0 0 (math.div($card-width,-2));

      -webkit-transition: margin 500ms ease-in-out, transform 500ms ease-in-out, z-index 500ms linear, opacity 500ms linear;
      -moz-transition: margin 500ms ease-in-out, transform 500ms ease-in-out, z-index 500ms linear, opacity 500ms linear;
      -ms-transition: margin 500ms ease-in-out, transform 500ms ease-in-out, z-index 500ms linear, opacity 500ms linear;
      -o-transition: margin 500ms ease-in-out, transform 500ms ease-in-out, z-index 500ms linear, opacity 500ms linear;
      transition: margin 500ms ease-in-out, transform 500ms ease-in-out, z-index 500ms linear, opacity 500ms linear;

      cursor: pointer;

    }
    .card-pos-1{
      margin:($card-height*0.7) 0 0  (math.div($card-width,2) + $card-width*0.8)*-1;
      transform: rotate(-18deg);
      z-index: 2;
      opacity: 0.75;
      &:hover{
        transform: rotate(-20deg);
      }
    }

    .card-pos-2{
      margin:($card-height*0.45) 0 0  (math.div($card-width,2) + $card-width*0.7)*-1;
      transform: rotate(-14deg);
      z-index: 3;
      opacity: 0.95;
      &:hover{
        transform: rotate(-16deg);
      }
    }
    .card-pos-3{
      margin:($card-height*0.20) 0 0  (math.div($card-width,2) + $card-width*0.5)*-1;
      transform: rotate(-12deg);
      z-index: 4;
      &:hover{
        transform: rotate(-14deg);
      }
    }
    .card-pos-4{
      z-index: 5;
      margin:0 0 0 (math.div($card-width,-2));
      &:hover{
        //transform: rotate(2deg);
        img{
          -webkit-animation:move-img-card 2s linear forwards infinite;
          -moz-animation:move-img-card 2s linear forwards infinite;
          animation:move-img-card 2s linear forwards infinite;
        }
      }
    }

    .card-pos-5{
      margin:($card-height*0.20) 0 0  ($card-width*0.5) - math.div($card-width,2);
      transform: rotate(12deg);
      z-index: 4;
      &:hover{
        transform: rotate(14deg);
      }
    }
    .card-pos-6
    {
      margin:($card-height*0.45) 0 0  ($card-width*0.7) - math.div($card-width,2);
      transform: rotate(14deg);
      z-index: 3;
      opacity: 0.95;
      &:hover{
        transform: rotate(16deg);
      }
    }

    .card-pos-7, .card-pos-8{

      margin:($card-height*0.7) 0 0  ($card-width*0.8 - math.div($card-width,2));
      transform: rotate(18deg);
      z-index: 2;
      opacity: 0.75;
      &:hover{
        transform: rotate(20deg);
      }
    }
    .card-pos-8{
      opacity:0;
      z-index: 1;
    }
  }

}

/** TEAM **/
section#team{
  #metastrat-illustrations{
    position: relative;
    min-height: 420px;
    .shape{

      position:absolute;
      &.emoji{
        opacity: 0;
      }
      &.emoji-rock{
        top:0;
        left:0;
        background-size:41px 60px;
        border-radius: 10%;
        box-shadow: 0 60px 60px 0 rgba(0, 0, 0, 0.13);
        z-index: 4;
      }
      &.primary-HH-bg{
        width: 57px;
        height: 57px;
        border-radius: 11px;
        top:277.5px;
        left:120px;
        z-index:3;
      }
      .shadow{
        width: 208px;
        height: 208px;
        z-index:3;
        border-radius: 44px;
      }
    }
    #photos{
      position: absolute;
      top:56px;
      left:5%;
      width:90%;
      max-width: 366px;
      height: 250px;
      background: no-repeat center center;
      background-size: cover;
      z-index:2;
      border-radius: 30px;
      @extend .anim-all-1s
    }

    #members{
      position: absolute;
      width: 234px;
      height: 234px;
      right:2%;
      bottom:0;
      overflow: hidden;
      z-index:4;
      background: #fff;
      box-shadow: 0 60px 60px 0 rgba(0, 0, 0, 0.13);
      border-radius: 39px;

      a{
        position: absolute;

        top:-200px;
        left:-200px;
        opacity: 0;
        $time:4000ms;
        $move: linear;
        -webkit-transition: top $time $move,  left $time $move;
        -moz-transition: top $time $move,  left $time $move;
        -ms-transition: top $time $move,  left $time $move;
        -o-transition: top $time $move,  left $time $move;
        transition: top $time $move,  left $time $move;

        $imgSize: 58px;
        $margin: 15px;
        $rowTop: 16px;
        $rowLeft: ($imgSize)*-1;
        $row : 0;
        $col : 0;
        @for $i from 1 through 15 {
          @if $i == 6 or $i==11 {
            $row: $row+1;
            @if $row == 1{
              $col: $col - 1;
            }
            @else{
              $col:0;
            }
          }

          &.m-pos-#{$i}{
            top:$rowTop+($margin+$imgSize)*$row;
            left:$rowLeft+($margin+$imgSize)*$col;

            opacity: 1;

          }
          @if $row==1{
            $col: $col - 1;
          }
          @else{
            $col: $col + 1;
          }

        }



      }
    }

  }

  p{
    span{
      color:$primary;
    }
  }
}
#partners{
  .partner{
    img{
      width: auto;

      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      @extend .anim-all;
      &:hover{
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
      }
    }
  }
}
#skills{
  overflow: hidden;
  min-height: 660px;
  #skill-titles{
    h4{
      border-radius:20px;
      border: 0px solid transparent;
      padding:5px 10px;
      cursor:pointer;
      @extend .anim-all;
      &:after{
        content:' > ';
        color:white;
        text-align: right;
      }
      &.on, &:hover{
        border-color: $primary;
        background-color: $primary;
        text-align: center;
        color:white!important;




      }
    }
  }


  #skill-desc{
    position: relative;

    .skill-desc{
      position: absolute;
      top:0;
      left:200%;
      z-index:1;
      background: #fff;
      height: 100%;
      width:100%;
      @extend .anim-all-1s;

      &.on{
        left:0;
        z-index: 2;

      }
    }
  }
  #emoji-bar{
    position: relative;
    height: 300px;
    .emoji{
      opacity:0;
    }
  }
}




form[name="contact_form"]{
  label{
    font-size:15px;
    @extend .font-15;
  }
  input, textarea{
    background: #fff;
    border:0px;
    border:1px solid $secondary-lighter;
    border-radius: 20px;
    @extend .font-16;
    @extend .font-md-22;
    color:#000;
    &::placeholder{
      color:$grey-dark;
    }
  }
  button{
    background-color: $secondary-lightest;
    color:$primary-100;
    &:hover{
      background-color: $secondary-light;
      color:$primary-100;
    }
  }
}
