@import 'mixin';
@import 'variables';


.anim-all {
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}
.anim-all-1s {
	-webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}


/* ROTATION */
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@-moz-keyframes spin-reverse { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin-reverse { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin-reverse { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

@for $i from 1 through 20{
	.rotate-#{$i}s {
		-webkit-animation:spin #{$i}s linear forwards infinite;
		-moz-animation:spin #{$i}s linear forwards infinite;
		animation:spin #{$i}s linear forwards infinite;

	}

	.rotate-reverse-#{$i}s{
		-webkit-animation:spin-reverse #{$i}s  linear forwards infinite;
		-moz-animation:spin-reverse #{$i}s  linear forwards infinite;
		animation:spin-reverse #{$i}s linear forwards infinite;
	}
}

@include keyframes(fadein-anim) {
	0% { opacity:0; display: none;}
	1% { opacity:0; display: block;}
	50%  {opacity : 1; max-height: 999px;}
	100%  {opacity : 1; max-height: 999px;}
}
@include keyframes(fadeout-anim) {
	0% {}
	50%  {opacity : 0;}
	100%  {opacity : 0; display:none; z-index:-1;}
}


@include keyframes(update-color) {
	0% {  }
	50%  { background:$primary; }
	95%  {  }
	100%  {  }
}

@include keyframes(move-shape) {
	0% { transform: translate3d(-5%, 0%, 0px) rotate(0) }
	50%  { transform: translate3d(350%, 50%, 0px) rotate(360deg); }
	95%  { transform: translate3d(-5%, 0%, 0px); }
	100%  { }
}

@include keyframes(bg-change) {
	0% { background-color: yellow }
	25%  { background-color: red}
	50%  { background-color: yellow}
	75%  { background-color: black}
	100%  { background-color: yellow }
}

@include keyframes(scall-n-rotate) {
	0%  { transform: scale(0) rotate(0); margin:0;}
	10%  {  transform: scale(0.1) rotate(180);margin:-20px 0 0 10px;}
	20%  {  transform: scale(0.2) rotate(360);margin:20px 0 0 -5px;}
	40%  {  transform: scale(0.4) rotate(-360);margin:0 0 0 20px;}
	60%  {  transform: scale(0.6) rotate(360);margin:-20px 0 0 -5px;}
	80%  { transform: scale(1) rotate(-360deg); margin:0; opacity: 1;}
	100%  { transform: scale(0.5) rotate(-360deg); opacity: 0; }
}


@include keyframes(scall-n-launch) {
	0%  { transform: scale(0); }
	50%  {  transform: scale(1) rotate(0deg);  }
	52%  {  transform: scale(1) rotate(3deg);}
	53%  {  transform: scale(1) rotate(-3deg);}
	54%  {  transform: scale(1) rotate(2deg); }
	55%  {  transform: scale(1) rotate(-2deg); }
	56%  {  transform: scale(1) rotate(-2deg); }
	56%  {  transform: scale(1) rotate(1deg);  top:330px;right:50px;  }
	100%  { top:-200px; right:-200px;}

}
.emoji{
	$time: 5s;


	&.-em-earth.on{
		opacity: 1;
		-webkit-animation:scall-n-rotate 10s linear forwards   1;
		-moz-animation:scall-n-rotate 10s linear forwards 1;
		animation:scall-n-rotate 10s linear forwards  1;
	}
	&.-em-rocket.on{
		opacity: 1;
		-webkit-animation:scall-n-launch $time linear forwards  1;
		-moz-animation:scall-n-launch $time linear forwards 1;
		animation:scall-n-launch $time linear forwards  1;
	}
}
	/*
.meta-title-shapes{
	$time: 10s;
-webkit-animation:update-color $time*2 linear forwards infinite;
	-moz-animation:update-color $time*2 linear forwards infinite;
	animation:update-color $time*2 linear forwards  infinite;

	.meta-title-shape{

		-webkit-animation:move-shape $time linear forwards infinite;
		-moz-animation:move-shape $time linear forwards infinite;
		animation:move-shape $time linear forwards infinite;

	}
}*/

@include keyframes(move-img-card) {
	0% {  }
	25%  { transform: rotate(3deg); }
	50%  { transform: rotate(0deg); }
	75%  { transform: rotate(-3deg); }
	100%  { }
}
.fadeout{

	-webkit-animation:fadeout-anim 1s linear forwards 1;
	-moz-animation:fadeout-anim 1s linear forwards 1;
	animation:fadeout-anim 1s linear forwards 1;
}

.fadein{
	-webkit-animation:fadein-anim 1s linear forwards 1;
	-moz-animation:fadein-anim 1s linear forwards 1;
	animation:fadein-anim 1s linear forwards 1;
}

@include keyframes (emoji-waving) {
	0% { top:10%; left:120%; }
	7% { opacity: 1; }
	15%  { top:20%;  }
	25%  { top:30%;  }
	30%  { top:25%; }
	35%  { top:35%; }
	45%  { top:45%; }
	55%  { top:51%; }
	65%  { top:45%; }
	75%  { top:35%; }
	85%  { top:45%; }
	95%  { top:52%; }
	99% { opacity: 1;}
	100%  { top:45%; left:-20%; opacity: 0;}
}

#skills{

	.emoji.on{
		opacity: 1;
		$time: 15s;
		-webkit-animation:emoji-waving $time ease-in-out forwards 1;
		-moz-animation:emoji-waving $time ease-in-out forwards 1;
		animation:emoji-waving $time ease-in-out forwards 1;
	}
}
