@import 'node_modules/bootstrap/scss/bootstrap';
//#@import 'node_modules/bootstrap-vue/src/index.scss';

@import 'anim';
@import 'variables';
@import 'mobile-v2';
@import '_shapes';
@import '_animatedTitle';
@import '_emoji';

/* layout animation */





#lbp-launch{
  background: $primary-120;
  @extend .anim-all;
  text-align:center;
  &:hover{
    background: $primary-dark;
  }

  a
  {

    &:hover{

      /*font-style: italic;
      b{
      text-decoration: underline;
    }*/
  }
}
}
nav.navbar-light{

  background: rgba(255,255,255,0.95);
  .navbar-nav{
    a.nav-link{
      color:$primary-120;
      font-size: 16px;

      &:hover{
        color:$primary;
      }
    }
  }
  img{
    vertical-align: top;
  }

}


blockquote{
  margin-left:15px;
  padding-left:15px;
  border-left:5px solid $secondary-lighter;
}

footer{
  #footer-logo{
    width: auto;
    max-height: 60px;
  }
  a{
    display: inline-block;

    color:white;
    &:hover{
      color: $secondary-lighter;
        text-decoration: none;
    }
  }



}
