
#achievements{


  text-align:center;
  .achievement{

    width:110px;
    height:110px;
    background: #fff;

    margin:5px;



    vertical-align: middle;

    @extend .anim-all;
    &:hover{
      transform: rotate(-1deg);
    }

    span{

      display: block;

      width: 100px;
      height: 100px;
      margin:5px;


      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

    }

    &#rift{
      background-color: #254C97;
    }
    &#positivr{
      background-color: #fe8d08;
    }
    &#humanity-lottery{
      background-color: #2dd3a1;
    }
    &#le-club-v{
      background-color: #252c41;
    }
    &#ekiwork{
      background-color: #fc6900;
    }

    &#on-passe-a-lacte{
      background-color: #E36A37;
    }





  }


}
