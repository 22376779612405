.bg-shape{
  position: absolute;
  z-index: 2;
  border-radius: 15%;
  &.-sq1{
    top:125px;
    left:-375px;
    width: 450px;
    height: 450px;

    border:2px solid $primary-120;
    @extend .rotate-reverse-20s;
    z-index: 2;

  }
  &.-sq2{
    top:510px;
    left:5%;
    width: 100px;
    height: 100px;
    background: transparent;
    border:1px solid rgba(0, 6, 70, 0.08);
    z-index: 3;
    @extend .rotate-10s;
  }
  &.-sq3{
    top:680px;
    left:8%;
    width: 50px;
    height: 50px;
    border:2px solid $primary-120;
    border-radius: 10%;
    @extend .rotate-reverse-9s;

  }
  &.-sq4{
    top:640px;
    left:3%;
    width: 34px;
    height: 34px;
    background: $primary;
    border:2px solid $primary;
    @extend .rotate-11s;
  }

  &.-sq6{
    top:275px;
    right:-325px;
    width: 450px;
    height: 450px;

    border:2px solid $primary-120;
    @extend .rotate-reverse-20s;
    z-index: 4;

  }
  &.-sq7{
    top:70px;
    right:-100px;
    width: 717px;
    height: 717px;
    background: rgba(0, 6, 70, 0.08);
    /*border:2px solid $primary-120;*/
    @extend .rotate-20s;
    z-index: 2;

  }
  &.-sq8{
    top:680px;
    right:8%;
    width: 50px;
    height: 50px;
    border:2px solid $primary-120;
    @extend .rotate-reverse-9s;

  }
  &.-sq9{
    top:640px;
    right:3%;
    width: 34px;
    height: 34px;
    background: $primary;
    border:2px solid $primary;
    @extend .rotate-11s;
  }
  &.-sq10{
    top:560px;
    right:6%;
    width: 45px;
    height: 45px;
    background: $primary-120;
    border:2px solid $primary-120;
    @extend .rotate-reverse-8s;
  }

}
