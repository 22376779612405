@import 'variables';

@media (min-width: 768px){

  .navbar-center {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
  }



}


@media (max-width: 768px){

  #main-content{
    z-index:6;
  }
  #cards{
    border-radius: 10px 10px 0 0!important;
  }

  nav{
    height: 50px;
    button.navbar-toggler{
      position: absolute;
      top:5px;

    }
    #top_menu_content{

      .navbar-collapse.show{
        box-shadow: 4px 4px 3px -3px rgba(0, 0, 0, 0.25);
        background: #fff;
        padding-bottom:20px;
        border-radius: 0 0 20px 20px;
      }
    }

    #menu{
      background: #fff;
    }
  }
  #skills{
    #skill-desc{
      .skill-desc{
        position: relative!important;
        overflow: auto!important;
        left:0!important;
        height: auto!important;
      }

    }
    #emoji-bar{
      display: none;
    }
  }



}
